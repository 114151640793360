@font-face { 
  font-family: gt-pressura-mono-regular; 
  src: url('/public/gt-pressura-mono-regular.woff'); 
} 

* {
  font-family: gt-pressura-mono-regular, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
      /* Hide scrollbars visually */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

p {
  margin: 0;
}

/* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.app {
  position: relative;
  height: 100%;
  background-color: white;
}

.dark-app {
  position: relative;
  height: 100%;
  background-color: black;
}

#logo {
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

#close-button {
  cursor: pointer;
}

.main-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
}

.main-header {
  position: sticky;
  top: 0px;
}


.filter-1 {
  background: #FFF;
  mix-blend-mode: screen;
}

.filter-2 {
  background-color: black;
  mix-blend-mode: luminosity;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: block !important;
}


.filter-3 {
  background: #FFFFFF;
  mix-blend-mode: exclusion;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: block !important;
}

#filter-1, #filter-2, #filter-3 {
  display: none;
}


#project-overlay {
  background-color: white;
  z-index: 3;
  position: absolute;
  top: 32px;
  width: 100%;
  height: calc(100% - 32px);
  padding: 16px;
}

#project-overlay-content {
  position: relative;
  height: 100%;
  overflow: scroll;
}

#project-overlay-heading {
  margin-bottom: 16px;
}

#project-overlay-heading-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-overlay-images {
  width: 100%;
  overflow: scroll;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.project-overlay-image {
  width: 100%;
}

.project-row {
  padding: 16px 0;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
}

.dark-project-row {
  border-top: 1px solid white !important;
}

.project-row-heading {
  display: flex;
  flex-direction: row;
  gap: 30px;
}



.project-row:last-child {
  border-bottom: 1px solid black;
}

.project-row-images {
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: scroll;
  margin: 0 -16px;
  padding: 0 16px;
}

.project-row-image {
  width: calc((100vw - 16px) * 7/16 - 16px);
}

.projects-container {
  position: relative;
  background-color: white;
  z-index: 5;
}

.dark-background{
  background-color: black !important;
  color: white !important;
}

#about-section {
  position: sticky;
  top: calc((100vw - 32px) * 102/343 + 16px) ;
  padding-bottom: 144px;
  background-color: white;
}

#desktop-scroll-group {
  position: relative;
  z-index: 3;
}

.project-row:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

#desktop-project-overlay-desc {
  display: none;
}


#project-overlay-wrapper {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.dark-description-link {
  color: white !important;
}

.dark-description-link::after {
  background-color: white !important;
}

.description-link {
  text-decoration: none;
  color: black;
  opacity: 1;
  transition: all 100ms ease-out;
  position: relative;
}



.description-link::after {
  content: " ";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;
  -webkit-transform: scale3d(0,1,1);
  transform: scale3d(0,1,1);
  transition: transform .4s cubic-bezier(.58,.3,.005,1);
  transform-origin: 100% 50%;
}

.description-link::after {
  height: 1px;
}

.description-link:hover::after{
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
  transition: transform .4s cubic-bezier(.58,.3,.005,1);
  transform-origin: 0 50%;
}

@media screen and (min-width: 800px) {
  
  .desktop-dark-background{
    background-color: black !important;
    color: white !important;
  }

  #about-section {
    position: sticky;
    top: -16px;
    padding-top: 16px;
    width: calc((100vw - 16px) * 5/16 - 16px);
    display: inline-block;
    vertical-align: top;
    margin-right: calc((100vw - 16px) * 1/16 + 16px);
  }

  #desktop-scroll-group {
    background: white;
  }

  .projects-container {
    padding-top: 16px;
    width: calc((100vw - 16px) * 10/16 - 16px);
    display: inline-block;
  }

  .project-row-images {
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
  }

  .project-row-image {
    width: calc((100vw - 16px) * 2/16 - 16px);
  }

  #project-overlay {
    top: 48px;
    left: 48px;
    width: calc(100vw - 96px);
    height: calc(100vh - 96px);
    padding: 0 16px;
  }

  #project-overlay-content {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
  }

  #project-overlay-heading {
    width: calc((100% + 16px) * 6/16 - 16px);
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0px;
  }

  .project-overlay-images {
    width: calc((100% + 16px) * 10/16 - 16px);
    height: 100%;
    padding: 16px 0px;
  }

  #desktop-project-overlay-desc {
    display: flex;
  }

  #project-overlay-desc {
    display: none;
  }
}

